@use "sass:map";
@import '../variables';
@import '../fonts';

body {
  @extend .flight-font;
  color: $primary-black;
  font-size: $font-size-medium;
  max-width: 75%;

}

.flight-time .flight-select__dropdown__trigger__button {
    width: unset;
}

.rule-frame {
  padding: $spacer-xlarge $spacer-large;
  border: $border-width solid $border-color;
  border-bottom: 0;

  &__flex {
    display: flex;
    justify-content: space-between;
  }

  &__nodeId {
    font-weight: $font-weight-bold;
    border-radius: $btn-border-radius;
    padding: $spacer-small $spacer-medium;
    color: $primary-blue;
    background-color: $blue-100;
  }

  &__ruleName {
    margin-left: $spacer-large;
  }

  &__toggleItem {
    cursor: pointer;
    padding: $spacer-xsmall;
    margin-left: $spacer-large;
    color: $neutral-color-900;
    border: unset;
    background-color: unset;
  }
}

.last-block {
  border-bottom: $border-width solid $border-color;
}

.rule-params, .rule-children {
  margin-top: $spacer-large;
  margin-left: $spacer-small * 9;
}

.rule-params {
  &__container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: $spacer-large;
  }

  &__paramName {
    margin-left: $spacer-xsmall;
  }

  &__paramItem {
    margin-left: $spacer-medium;
  }
}

.rule-children {
  display: flex;
  flex-flow: row wrap;

  &__item {
    margin-top: $spacer-medium;
    margin-right: $spacer-large;
  }
}

.rule-condition {
  background-color: $neutral-color-50;
  height: 46px;
  margin-bottom: -$spacer-xlarge * 1.3;
  border: 1px solid $neutral-color-200;
  position: relative;

  &__condition {
    font-size: $font-size-small;
    position: absolute;
    padding: $spacer-large;
  }
  
}